import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuthState } from '../services/authContext';

interface Props extends RouteProps {}

export const PrivateRoute: React.FC<Props> = (props: any) => {
  const userDetails = useAuthState();
  const { login } = userDetails;
  //const isAuthenticated = userDetails.isAuthenticated;
  //const isAuthenticated = false;

  const { component: Component, ...restProps } = props;

  if (!Component) return null;

  return (
    <Route
      {...restProps}
      render={(routeRenderProps) =>
        login.isAuthenticated ? (
          <Component {...routeRenderProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};
