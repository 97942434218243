export const HOME = '/';
export const LIMIT_MIN = 10;
export const LIMIT = 50;
export const LIMIT100 = 100;
export const PER_PAGE = 10;

export const HOME_URL = process.env.REACT_APP_FRONTEND_URL;

export const EMAIL_REQUIRED = 'Email is required';
export const PASSWORD_REQUIRED = 'Password is required';
export const PASSWORD_DONT_MATCH = 'Passwords do not match';
export const EMAIL_INVALID = 'Email address is invalid';
export const NAME_REQUIRED = 'Name is required';

export const PRODUCT_PASSES = 'passes';
export const PRODUCT_PACKS = 'packs';
export const PRODUCT_PARKING = 'parking';

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const EXTRA = 'extra';
export const EXTRA_TYPE = 'extra-type';
export const STOWAGE = 'stowage';

export const TYPES = [
  { label: 'Packs', value: 'PACKS' },
  { label: 'Passes', value: 'PASSES' },
];

export const WIDGET_PLACEMENT = [
  { label: 'Section 1', value: 'SECTION 1' },
  { label: 'Section 2', value: 'SECTION 2' },
];

export const RIDE_AMOUNT_TYPE = [
  { label: 'Unlimited', value: 'unlimited' },
  { label: 'Limited', value: 'limited' },
];

export const ACTIVATION_RULE = [
  { label: 'Start at purchase', value: 'start_at_purchase' },
  // { label: 'First use', value: 'first_use' },
  // { label: 'Specified Date Range', value: 'specified_date_range' },
];

export const PAYMENT_ALLOCATION = [
  // { label: 'PACKS', value: 'PACKS' },
  // { label: 'PASSES', value: 'PASSES' },
  // { label: 'WEEKEND_PASS', value: 'WEEKEND_PASS' },
  { label: 'Add new...', value: 'ADD_NEW' },
];
