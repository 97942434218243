import 'react-datepicker/dist/react-datepicker.css';

import './App.css';

import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, Router, Switch } from 'react-router-dom';

import {
  PrivateRoutingOptions,
  PublicRoutingOptions,
} from './components/common/routes';
import { history } from './configureStore';
import NotFoundPage from './pages/404';
import { LayoutAuthenticated } from './routes/LayoutPrivate';
import { LayoutAnonymous } from './routes/LayoutPublic';
import { PrivateRoute } from './routes/PrivateRoute';
import { PublicRoute } from './routes/PublicRoute';
import { privateRoutes, publicRoutes } from './routes/Routes';
import { AuthProvider } from './services/authContext';

function App() {
  return (
    <AuthProvider>
      <Router history={history}>
        <Switch>
          <Route exact path={PrivateRoutingOptions}>
            <LayoutAuthenticated>
              <Switch>
                {privateRoutes.map((privateRouteProps) => (
                  <PrivateRoute
                    {...privateRouteProps}
                    key={privateRouteProps.path}
                  />
                ))}
              </Switch>
            </LayoutAuthenticated>
          </Route>

          <Route exact path={PublicRoutingOptions}>
            <LayoutAnonymous>
              <Switch>
                {publicRoutes.map((publicRouteProps) => (
                  <PublicRoute
                    {...publicRouteProps}
                    key={publicRouteProps.path}
                  />
                ))}
              </Switch>
            </LayoutAnonymous>
          </Route>

          <Route path="*">
            <LayoutAnonymous>
              <Switch>
                <Route component={NotFoundPage} />
              </Switch>
            </LayoutAnonymous>
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
