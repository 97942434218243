import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

import webfox from './initialize';

const logError = (error) => {
  console.log('system error: ', error);
};
toast.configure();

const notify = () => {
  toast.dismiss();
  setTimeout(function () {
    toast.error('ACCESS DENIED!!!', {
      position: toast.POSITION.TOP_CENTER,
    });
  }, 1000);
};

const basicToken =
  process.env.REACT_APP_BASIC_AUTH_TOKEN ||
  'RVBHSmtxY1QyM3lmd1FEY2h2Yk46T01LWFdWZmt4V0IzNEsxcWhyS1Q=';

webfox.interceptors.request.use(
  async (config) => {
    const userJson = localStorage.getItem('currentUser');
    const token = userJson !== null ? JSON.parse(userJson).token : '';
    config.headers.Authorization = token
      ? `Bearer ${token}`
      : `Basic ${basicToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

webfox.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      notify();
      return Promise.reject(error);
    } else if (error.response && error.response.status === 401) {
      localStorage.clear();
      // window.location.href = '/';
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const exec = async (fn, params = null, error = null) => {
  const res = {
    data: null,
    error: null,
    syserror: null,
  };

  await fn(params)
    .then((response) => {
      res.data = response;
    })
    .catch((err) => {
      if (err.response) {
        res.error = err.response;
      } else if (err.request) {
        res.error = err.request;
      } else {
        res.error = err;
      }
    });

  return res;
};

const login = (params) =>
  exec(() => {
    return webfox.post(`/ops/users/login`, params);
  });

const addUser = (params) =>
  exec(() => {
    return webfox.post('user/', params);
  });

const forgot = (params) =>
  exec(() => {
    return webfox.post('/ops/users/forgot-password', params);
  });

const reset = (params) =>
  exec(() => {
    return webfox.post('/ops/users/change-password', params);
  });

const verify = (params) =>
  exec(() => {
    return webfox.post('/ops/users/verify/', params);
  });

const getUsersList = (params) =>
  exec(() => {
    return webfox.get('/user/', { params });
  });

const deleteUser = (params) =>
  exec(() => {
    return webfox.delete(`/user/${params}`);
  });
const getUser = (params) =>
  exec(() => {
    return webfox.get(`/user/${params}`);
  });

const updateUser = (params, paramsId) =>
  exec(() => {
    return webfox.put(`/user/${paramsId}`, params);
  });

const updateUserStatus = (params) =>
  exec(() => {
    return webfox.patch(`/user/${params.id}/${params.status_type}`, params);
  });
export default {
  login,
  logError,
  addUser,
  forgot,
  reset,
  verify,
  getUsersList,
  deleteUser,
  getUser,
  updateUser,
  updateUserStatus,
};
