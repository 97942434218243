import React, { useReducer, useState } from 'react';

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        login: {
          ...initialState.login,
          loading: true,
        },
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        login: {
          ...initialState.login,
          role: action.payload.role,
          token: action.payload.token,
          loading: false,
          isAuthenticated: true,
        },
      };
    case 'LOGOUT':
      return {
        ...initialState,
        login: {
          ...initialState.login,
          role: '',
          token: '',
          isAuthenticated: false,
        },
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        login: {
          ...initialState.login,
          loading: false,
          errorMessage: action.error,
        },
      };
    case 'REQUEST_RESET_PASSWORD':
      return {
        ...initialState,
        resetPassword: {
          ...initialState.resetPassword,
          loading: true,
        },
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...initialState,
        resetPassword: {
          ...initialState.resetPassword,
          email: action.payload.user,
          loading: false,
        },
      };
    case 'RESET_PASSWORD_ERROR':
      return {
        ...initialState,
        resetPassword: {
          ...initialState.resetPassword,
          loading: false,
          errorMessage: action.error,
        },
      };
    case 'REQUEST_FORGOT_PASSWORD':
      return {
        ...initialState,
        forgotPassword: {
          ...initialState.forgotPassword,
          loading: true,
        },
      };
    case 'FORGOT_PASSWORD_SUCCESS':
      return {
        ...initialState,
        forgotPassword: {
          ...initialState.forgotPassword,
          email: action.payload.user,
          loading: false,
        },
      };
    case 'FORGOT_PASSWORD_ERROR':
      return {
        ...initialState,
        forgotPassword: {
          ...initialState.forgotPassword,
          loading: false,
          errorMessage: action.error,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
