import axios from 'axios';

const webfoxConfig: any = {
  headers: {
    'Content-Type': 'application/json',
    channel: 'opstool',
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  responseType: 'json',
};

export default axios.create(webfoxConfig);
