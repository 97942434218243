import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import webfox from './webbase';
import { StoreContext, actions } from './webstore';

const WebfoxContext: any = createContext<{
  fetchRecommendations: () => null;
}>({
  fetchRecommendations: () => null,
});

const WebfoxProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const { state, actionDispatch } = useContext(StoreContext);
  // const [showAddUser, setShowAddUser] = useState(false);
  // const [showEditUser, setShowEditUser] = useState(false);
  // const [showAddRoles, setShowAddRoles] = useState(false);

  return (
    <WebfoxContext.Provider
      value={{
        webfox,
        loading,
        setLoading,
        loadingMore,
        setLoadingMore,
        webstore: state,
        actions,
        actionDispatch,
        // showAddUser,
        // setShowAddUser,
        // showEditUser,
        // setShowEditUser,
        // showAddRoles,
        // setShowAddRoles,
      }}
    >
      {children}
    </WebfoxContext.Provider>
  );
};

WebfoxProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { WebfoxContext, WebfoxProvider };
