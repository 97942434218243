import React from 'react';
import { Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.svg';
import { HOME } from '../utils/constants';

const Index = () => {
  return (
    <div
      style={{
        minHeight: 'calc(100vh - 500px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image src={logo} alt="logo" />
      <Helmet title="Not Found" />
      <br />
      <br />
      <h2 className="">Page not found.</h2>
      <br />
      <br />
      <Link to={HOME} className="btn">
        &larr; Go back to the home page
      </Link>
    </div>
  );
};

export default Index;
