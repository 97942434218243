import api from './queries';

const logerror = api.logError;

const auth = () => {};
const webfoxstore = () => {};

const login: any = async (params: any) => {
  const res = await api.login(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const forgot: any = async (params: any) => {
  const res = await api.forgot(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const reset: any = async (params: any) => {
  const res = await api.reset(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const verify: any = async (params: any) => {
  const res = await api.verify(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const getAllUsers: any = async (params: any) => {
  const res = await api.getUsersList(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const deleteUser: any = async (params: any) => {
  const res = await api.deleteUser(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const getUser: any = async (params: any) => {
  const res = await api.getUser(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const updateUser: any = async (params: any, paramsId: any) => {
  const res = await api.updateUser(params, paramsId).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

const updateUserStatus: any = async (params: any) => {
  const res = await api.updateUserStatus(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export default {
  login,
  auth,
  webfoxstore,
  forgot,
  reset,
  verify,
  getAllUsers,
  deleteUser,
  getUser,
  updateUser,
  updateUserStatus,
};
