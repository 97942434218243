import React from 'react';
import Loadable from 'react-loadable';

import NotFoundPage from '../pages/404';

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: Loading,
  });

function Loading() {
  return <div></div>;
}

export const publicRoutes = [
  {
    key: 'login',
    path: '/login',
    component: loadable(() => import('../pages/login')),
    exact: true,
  },
  {
    key: 'forgot-password',
    path: '/forgot-password',
    component: loadable(() => import('../pages/forgotpassword')),
    exact: true,
  },
  {
    key: 'landing',
    path: '/landing',
    component: loadable(() => import('../pages/landing')),
    exact: true,
  },
];

export const privateRoutes = [
  {
    path: '/dashboard',
    exact: true,
    component: loadable(() => import('../pages/dashboard')),
  },
  {
    path: '/user',
    exact: true,
    component: loadable(() => import('../pages/user')),
  },
  {
    path: '/extras',
    exact: true,
    component: loadable(() => import('../pages/extras')),
  },
  {
    path: '/parkingpass',
    exact: true,
    component: loadable(() => import('../pages/parkingPass')),
  },
  {
    path: '/travelpack',
    exact: true,
    component: loadable(() => import('../pages/travelpack')),
  },
  {
    key: 'settings',
    path: '/settings',
    component: loadable(() => import('../pages/settings')),
    exact: true,
  },
  {
    path: '/trainpass',
    exact: true,
    component: loadable(() => import('../pages/trainpass')),
  },
  {
    path: '/ridepass',
    exact: true,
    component: loadable(() => import('../pages/ridePass')),
  },
  {
    path: '/mdtapass',
    exact: true,
    component: loadable(() => import('../pages/mdtaPass')),
  },
  {
    path: '/employeeBenifits',
    exact: true,
    component: loadable(() => import('../pages/employeeBenifits')),
  },
  {
    path: '/corporateDiscount',
    exact: true,
    component: loadable(() => import('../pages/corporateDiscount')),
  },
  {
    path: '/agentPortal',
    exact: true,
    component: loadable(() => import('../pages/agentPortal')),
  },
  {
    path: '/credits',
    exact: true,
    component: loadable(() => import('../pages/credits')),
  },
  {
    path: '/doortodoor',
    exact: true,
    component: loadable(() => import('../pages/doortodoor')),
  },
  {
    path: '/',
    exact: true,
    component: loadable(() => import('../pages/dashboard')),
  },
];
