import api, { GetPassesListParams } from './queries';

const logerror = api.logError;

export const getAllStations: any = async (params: any) => {
  const res = await api.getAllStations(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getAllRoutes: any = async (params: any) => {
  const res = await api.getAllRoutes(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getGarages: any = async (params: any) => {
  const res = await api.getGarages(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addGarage: any = async (params: any) => {
  const res = await api.addGarage(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const updateGarage: any = async (params: any) => {
  const res = await api.updateGarage(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const deleteGarage: any = async (params: any) => {
  const res = await api.deleteGarage(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getAllUsers: any = async (params: any) => {
  const res = await api.getAllUsers(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addUser: any = async (params: any) => {
  const res = await api.addUser(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const deleteUser: any = async (params: any) => {
  const res = await api.deleteUser(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const forgotPassword: any = async (params: any) => {
  const res = await api.forgotPassword(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const resetPassword: any = async (params: any) => {
  const res = await api.resetPassword(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const trainPassValue: any = async (params: any) => {
  const res = await api.getTrainPassValue(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getPacks: any = async (params: any) => {
  const res = await api.getPacks(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const trainPassList: any = async (params: any) => {
  const res = await api.getTrainPassList(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getExistingPacks: any = async (params: any) => {
  const res = await api.getExistingPacks(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addTrainPass: any = async (params: any) => {
  const res = await api.addPass(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addPaymentAllocation: any = async (params: any) => {
  const res = await api.addPaymentAllocationItem(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const createPack: any = async (params: any) => {
  const res = await api.createPack(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const editProduct: any = async (params: any) => {
  const res = await api.editPass(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const deletePack: any = async (params: any) => {
  const res = await api.deletePack(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addTrainPassSales: any = async (params: any) => {
  const res = await api.addPassSale(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addRedirectStations: any = async (params: any) => {
  const res = await api.addRedirectStation(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const updateRedirectStations: any = async (params: any) => {
  const res = await api.updateRedirectStation(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addPackSale: any = async (params: any) => {
  const res = await api.addPackSale(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const salesTrainPassList: any = async (params: any) => {
  const res = await api.getSalesTrainPassList(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getRedirectStationList: any = async (params: string) => {
  const res = await api.getRedirectStationList(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getPackSale: any = async (params: any) => {
  const res = await api.getPackSale(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const deletePackSales: any = async (params: any) => {
  const res = await api.deletePackSales(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const editPackSales: any = async (params: any) => {
  const res = await api.editPackSales(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const inventoryTrainPassList: any = async (params: any) => {
  const res = await api.getInventoryTrainPassList(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const inventoryMDTAPassList: any = async (params: any) => {
  const res = await api.getinventoryMDTAPassList(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getPacksInventory: any = async (params: any) => {
  const res = await api.getPacksInventory(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getDiscountTextData: any = async (params: any) => {
  const res = await api.getDiscountText(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getSubscriptionDiscountData: any = async (params: any) => {
  const res = await api.getSubscriptionDiscountData(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const updatePacksInventory: any = async (params: any) => {
  const res = await api.updatePacksInventory(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getSubcriptionData: any = async (params: any) => {
  const res = await api.getSubscription(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getRoutesData: any = async (params: string) => {
  const res = await api.getRoutes().catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getPaymentAllocationList: any = async (params: string) => {
  const res = await api.getPaymentAllocationList().catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getStationRidePasses: any = async (
  params: GetPassesListParams
) => {
  const res = await api.getRidePassesListForStation(params).catch((e) => {
    logerror('getStationPassesByType error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const editPassesSwitch: any = async (params: any) => {
  const res = await api.passesSwitch(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const editPassesMDTASwitch: any = async (params: any) => {
  const res = await api.passesMDTASwitch(params).catch((e) => {
    logerror('At editPassesMDTASwitch: routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getDisscountPasses: any = async (params: any) => {
  const res = await api.disscountPasses(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const updateDiscountPasses: any = async (params: any) => {
  const res = await api.updateDiscountPass(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const updateDiscountTextPass: any = async (params: any) => {
  const res = await api.updateDiscountText(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const assignEmployeeEmail: any = async (params: any) => {
  const res = await api.assignEmployeeEmail(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const searchEmployeeEmail: any = async (params: any) => {
  const res = await api.searchEmployeeEmail(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const deleteEmployeeEmail: any = async (params: any) => {
  const res = await api.deleteEmployeeEmail(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getExtras: any = async (params: any) => {
  const res = await api.getExtras(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getExtraPrivateRidePrice: any = async (params: any) => {
  const res = await api.getExtraPrivateRidePrice(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getExtraSharedRidePrice: any = async (params: any) => {
  const res = await api.getExtraSharedRidePrice(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const getExtraParking: any = async (params: any) => {
  const res = await api.getExtraParking(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const putExtrasInventory: any = async (params: any) => {
  const res = await api.putExtrasInventory(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const putExtrasPrice: any = async (params: any) => {
  const res = await api.putExtrasPrice(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const putExtraDisabledStations: any = async (params: any) => {
  const res = await api.putExtraDisabledStations(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const putExtraPrivateRidePrice: any = async (params: any) => {
  const res = await api.putExtraPrivateRidePrice(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const putExtraSharedRidePrice: any = async (params: any) => {
  const res = await api.putExtraSharedRidePrice(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const putExtraPrivateRideCount: any = async (params: any) => {
  const res = await api.putExtraPrivateRideCount(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;

  return { data: val ? val.data : null, error: res.error };
};

export const addARDiscount: any = async (params: any) => {
  const res = await api.addARDiscount(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const addSales: any = async (params: any) => {
  const res = await api.addSales(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getSales: any = async (params: any) => {
  const res = await api.getSales(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const addProduct: any = async (params: any) => {
  const res = await api.addProduct(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getProducts: any = async (params: any) => {
  const res = await api.getProducts(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getConfigDetails: any = async (params: any) => {
  const res = await api.getConfigDetails(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const editSales: any = async (params: any) => {
  const res = await api.editSales(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const deleteSales: any = async (params: any) => {
  const res = await api.deleteSales(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const deleteRedirectStations: any = async (params: any) => {
  const res = await api.deleteRedirectStation(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const addAgents: any = async (params: any) => {
  const res = await api.addAgents(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getAgents: any = async (params: any) => {
  const res = await api.getAgents(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const deleteAgents: any = async (params: any) => {
  const res = await api.deleteAgents(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const addCredits: any = async (params: any) => {
  const res = await api.addCredits(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getCredits: any = async (params: any) => {
  const res = await api.getCredits(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const addDomains: any = async (params: any) => {
  const res = await api.addDomains(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getDomains: any = async (params: any) => {
  const res = await api.getDomains(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getDiscounts: any = async (params: any) => {
  const res = await api.getDiscounts(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const updateDiscounts: any = async (params: any) => {
  const res = await api.updateDiscounts(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const deleteDomain: any = async (params: any) => {
  const res = await api.deleteDomain(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const updateDomain: any = async (params: any) => {
  const res = await api.updateDomain(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const updateDtoDConfig: any = async (params: any) => {
  const res = await api.updateDtoDConfig(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getD2dConfig: any = async (params: any) => {
  const res = await api.getD2dConfig(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const updateServiceWindow: any = async (params: any) => {
  const res = await api.updateServiceWindow(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getDailyParkingPrice: any = async (params: any) => {
  const res = await api.getDailyParkingPrice(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const updateParkingPrice: any = async (params: any) => {
  const res = await api.updateParkingPrice(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const getToggleSwitchData: any = async (params: any) => {
  const res = await api.getToggleSwitchData(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};

export const updateToggleSwitchData: any = async (params: any) => {
  const res = await api.updateToggleSwitchData(params).catch((e) => {
    logerror('routes error: ' + e);
    return { data: null, error: e };
  });
  const val: any = res ? res.data : null;
  return { data: val ? val.data : null, error: res.error };
};
