const userJson = localStorage.getItem('currentUser');
const role = userJson !== null ? JSON.parse(userJson).role : '';
const token = userJson !== null ? JSON.parse(userJson).token : '';
const isAuthenticated =
  userJson !== null ? JSON.parse(userJson).isAuthenticated : '';

export default {
  login: {
    role: '' || role,
    token: '' || token,
    loading: false,
    errorMessage: null,
    isAuthenticated: false || isAuthenticated,
  },
  forgotPassword: {
    data: [],
    loading: false,
    errorMessage: null,
  },
  resetPassword: {
    email: [],
    loading: false,
    errorMessage: null,
  },
};
