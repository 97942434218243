import './style.css';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col>
          © OPS-TOOL | Brightline - {new Date().getFullYear()}. All rights
          reserved
        </Col>
      </Row>
    </Container>
  );
}
