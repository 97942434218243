import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Footer } from '../components/footer';
import LeftSideBar from '../components/leftsidebar/LeftSideBar';
import { TopNavbar } from '../components/nav/TopNavbar';
import ErrorBoundary from '../services/logAnalytics';

export const LayoutAuthenticated: React.FC<{}> = (props) => {
  return (
    <>
      <div className="app-core">
        <header className="header">
          <TopNavbar />
        </header>
        <main>
          <Container fluid>
            <Row>
              <Col className="padding-0 left-navi">
                <ErrorBoundary name={'LeftSideBar'}>
                  <LeftSideBar />
                </ErrorBoundary>
              </Col>
              <Col className="tabs-scroll page-content">
                <ErrorBoundary name={'pages'}>
                  {props.children}
                  <Footer />
                </ErrorBoundary>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </>
  );
};

LayoutAuthenticated.propTypes = {
  children: PropTypes.node,
};
