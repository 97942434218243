import React, { useContext } from 'react';

import Auth from './routes';

export function loginUser(dispatch, loginPayload) {
  const params = {} as any;
  params['email_id'] = loginPayload.email;
  params['password'] = loginPayload.password;

  let loginData;
  return Auth.login(params).then(({ data, error }) => {
    dispatch({ type: 'LOGIN_ERROR', data: [] });
    if (error) {
      const errorData = {
        status: error.data.code,
        statusText: error.data.message,
      };
      dispatch({ type: 'LOGIN_ERROR', error: error });
      return errorData;
    } else {
      loginData = {
        role: '' || (data && data.data && data.data.role),
        token: '' || (data && data.data && data.data.token),
        loading: false,
        errorMessage: null,
        isAuthenticated: true,
      };
      localStorage.setItem('currentUser', JSON.stringify(loginData));
      dispatch({ type: 'LOGIN_SUCCESS', payload: loginData });
      return loginData;
    }
  });
}

export function forgot(dispatch, loginPayload) {
  const params = { email_id: loginPayload.email };
  let loginData;
  return Auth.forgot(params).then(({ data, error }) => {
    dispatch({ type: 'FORGOT_PASSWORD_ERROR', data: [] });
    if (error) {
      const errorData = {
        status: error.status,
        statusText: error.statusText,
      };
      dispatch({ type: 'FORGOT_PASSWORD_ERROR', error: error });
      return errorData;
    } else {
      loginData = {
        email: '' || data.email,
        message: '' || data.msg,
        verification: '' || data.verification,
        loading: false,
      };
      dispatch({ type: 'FORGOT_PASSWORD_SUCCESS', payload: loginData });
      return loginData;
    }
  });
}

export function reset(dispatch, loginPayload) {
  const params = new URLSearchParams();
  params.append('id', loginPayload.key);
  params.append('password', loginPayload.password);
  let loginData;
  const returnData = Auth.reset(params).then(({ data, error }) => {
    dispatch({ type: 'RESET_PASSWORD_ERROR', data: [] });
    if (error) {
      const errorData = {
        status: error.status,
        statusText: error.statusText,
      };
      dispatch({ type: 'RESET_PASSWORD_ERROR', error: error });
      return errorData;
    } else {
      loginData = {
        message: '' || data.msg,
        loading: false,
      };
      dispatch({ type: 'RESET_PASSWORD_SUCCESS', payload: loginData });
      return loginData;
    }
  });
  return returnData;
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
}
