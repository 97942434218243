import './dropdown.css';

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

const clearHandler = (props: any) => {
  const eleme: any = document.querySelectorAll('#dropdown-btn');
  for (let i = 0; i < eleme.length; i++) {
    eleme[i].parentNode.parentNode.childNodes[0].classList.remove(
      'active-menu'
    );
    eleme[i].childNodes[1].classList.remove('act');
  }
};

const DEFAULT_MENU = '/dashboard';

const DropDown = (props: any) => {
  const pathname =
    props.location && props.location.pathname === '/'
      ? DEFAULT_MENU
      : props.location.pathname;

  const displaySelect = (label, displayStyle, ele) => {
    const dropdownContent = ele.nextElementSibling;
    const icon = ele.childNodes[1];
    if (icon === undefined) {
      return;
    }

    icon.classList.toggle('act');
    // if (label !== 'Ranking' && dropdownContent.style.display === displayStyle) {
    //   dropdownContent.style.display = 'none';
    // }
  };

  const dropDownHAndler = (event) => {
    const enode = event.target.parentNode;

    if (
      enode.getAttribute('link-menu') !== undefined &&
      enode.getAttribute('link-menu') !== null
    ) {
      if (!enode.classList.contains('menu-active')) {
        clearHandler(props);
      }
      if (
        // props.label !== 'Ranking' &&
        !enode.parentNode.parentNode.childNodes[0].classList.contains(
          'active-menu'
        )
      ) {
        clearHandler(props);
      }

      props.history.push(enode.getAttribute('link-menu'));
      if (
        enode &&
        enode.classList &&
        enode.classList.getAttribute &&
        enode.classList.getAttribute('link-menu').split('/')[1] !=
          pathname.split('/')[1]
      ) {
        enode.classList.toggle('menu-active');
        // props.label !== 'Ranking' &&
        enode.parentNode.parentNode.childNodes[0].classList.toggle(
          'active-menu'
        );
      }

      const ele = enode;
      // displaySelect('Ranking', 'flex', ele);
    } else if (
      event.target.getAttribute('link-menu') !== undefined &&
      event.target.getAttribute('link-menu') !== null
    ) {
      if (!event.target.classList.contains('menu-active')) {
        clearHandler(props);
      }
      if (
        // props.label !== 'Ranking' &&
        !enode.parentNode.childNodes[0].classList.contains('active-menu')
      ) {
        clearHandler(props);
      }

      props.history.push(event.target.getAttribute('link-menu'));
      if (
        event &&
        event.target &&
        event.target.getAttribute &&
        event.target.getAttribute('link-menu').split('/')[1] !=
          pathname.split('/')[1]
      ) {
        event.target.classList.toggle('menu-active');
        // props.label !== 'Ranking' &&
        enode.parentNode.childNodes[0].classList.toggle('active-menu');
      }

      const ele = event.target;
      // displaySelect('Ranking', 'block', ele);
    } else if (
      enode.parentNode.getAttribute('link-menu') !== undefined &&
      enode.parentNode.getAttribute('link-menu') !== null
    ) {
      if (!enode.parentNode.classList.contains('menu-active')) {
        clearHandler(props);
      }
      if (
        // props.label !== 'Ranking' &&
        !enode.parentNode.parentNode.parentNode.childNodes[0].classList.contains(
          'active-menu'
        )
      ) {
        clearHandler(props);
      }

      props.history.push(enode.parentNode.getAttribute('link-menu'));
      if (
        enode &&
        enode.parentNode &&
        enode.parentNode.getAttribute &&
        enode.parentNode.getAttribute('link-menu').split('/')[1] !=
          pathname.split('/')[1]
      ) {
        enode.parentNode.classList.toggle('menu-active');
        // props.label !== 'Ranking' &&
        enode.parentNode.parentNode.parentNode.childNodes[0].classList.toggle(
          'active-menu'
        );
      }

      const ele = enode.parentNode;
      // displaySelect('Ranking', 'block', ele);
    }
  };

  const pathIncludesSource = () => {
    if (
      pathname.includes('source') &&
      pathname.includes('compare') &&
      props.route === '/source'
    ) {
      return true;
    } else if (
      pathname.includes(props.route) &&
      !pathname.includes('compare')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const activeClass = pathIncludesSource() ? 'menu-active' : '';
  const activeDropdown = pathIncludesSource() ? 'flex' : 'none';
  const active = pathIncludesSource() ? 'act' : '';
  const activeRight = pathIncludesSource() ? 'fa-caret-right' : 'fa-caret-down';

  return (
    <React.Fragment>
      {props.children && props.children.length >= 1 ? (
        <React.Fragment>
          <div className="lhs-menu">
            <div className=""></div>
            <div>
              <div
                className={`dropdown-btn css-flex wrap ${activeClass}`}
                id="dropdown-btn"
                link-menu={props.route}
                onClick={dropDownHAndler}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '140px',
                  }}
                >
                  <img className="v-top" src={props.img} />
                  <span className="dropdown-title dropdown-title-active">
                    {props.label}
                  </span>
                </div>
                <i className={`fa fa-caret-down ${active}`}></i>
              </div>
              <div
                className="dropdown-container wrap"
                style={{ display: activeDropdown }}
              >
                {props.label === 'User Roles' || props.label === 'Users'
                  ? props.children.map((value, index, arr) => {
                      if (value.props.children === 'See All') {
                        return (
                          <NavLink
                            key={index}
                            to={value.props['route-path']}
                            exact
                          >
                            <li className="subMenu-container css-flex ">
                              <div className="subMenu-container-div">
                                <div>
                                  <img
                                    className="sub-child-img"
                                    src={value.props['icon-img']}
                                  />
                                </div>
                                <div className="sub-child wrap">
                                  {value.props.children}
                                </div>
                                <div className="right-Arrow"></div>
                              </div>
                            </li>
                          </NavLink>
                        );
                      }

                      return (
                        <>
                          <li className="subMenu-container css-flex ">
                            <div className="subMenu-container-div">
                              <div>
                                <img
                                  className="sub-child-img"
                                  src={value.props['icon-img']}
                                />
                              </div>
                              <div className="sub-child wrap">
                                {value.props.children}
                              </div>
                              <div className="right-Arrow"></div>
                            </div>
                          </li>
                        </>
                      );
                    })
                  : props.label === 'Ingestion'
                  ? props.children.map((value, index, arr) => {
                      if (value.props.children === 'See All') {
                        return (
                          <NavLink
                            key={index}
                            to={value.props['route-path']}
                            exact
                          >
                            <li className="subMenu-container css-flex ">
                              <div className="subMenu-container-div">
                                <div>
                                  <img
                                    className="sub-child-img"
                                    src={value.props['icon-img']}
                                  />
                                </div>
                                <div className="sub-child wrap">
                                  {value.props.children}
                                </div>
                                <div className="right-Arrow"></div>
                              </div>
                            </li>
                          </NavLink>
                        );
                      }
                    })
                  : props.children.map((value, index, arr) => {
                      return (
                        <NavLink
                          key={index}
                          to={value.props['route-path']}
                          exact
                        >
                          <li className="subMenu-container css-flex ">
                            <div className="subMenu-container-div">
                              <div>
                                <img
                                  className="sub-child-img"
                                  src={value.props['icon-img']}
                                />
                              </div>
                              <div className="sub-child wrap">
                                {value.props.children}
                              </div>
                              <div className="right-Arrow"></div>
                            </div>
                          </li>
                        </NavLink>
                      );
                    })}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div
          className={`dropdown-btn css-flex wrap ${activeClass}`}
          id="dropdown-btn"
          link-menu={props.route}
          onClick={dropDownHAndler}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', width: '140px' }}
          >
            <img className="v-top" src={props.img} />
            <span className="dropdown-title dropdown-title-active">
              {props.label}
            </span>
          </div>
          <i className={`fa ${activeRight}`}></i>
        </div>
      )}
    </React.Fragment>
  );
};

const linkHandler = (value, index, arr) => {
  if (value.props.children === 'See All') {
    return (
      <NavLink key={index} to={value.props['route-path']} exact>
        <li className="subMenu-container css-flex ">
          <div className="subMenu-container-div">
            <div>
              <img className="sub-child-img" src={value.props['icon-img']} />
            </div>
            <div className="sub-child wrap">{value.props.children}</div>
            <div className="right-Arrow"></div>
          </div>
        </li>
      </NavLink>
    );
  }

  return (
    <>
      <li className="subMenu-container css-flex ">
        <div className="subMenu-container-div">
          <div>
            <img className="sub-child-img" src={value.props['icon-img']} />
          </div>
          <div className="sub-child wrap">{value.props.children}</div>
          <div className="right-Arrow"></div>
        </div>
      </li>
    </>
  );
};

DropDown.propTypes = {
  label: PropTypes.string,
  history: PropTypes.array,
  children: PropTypes.element.isRequired,
  route: PropTypes.string,
  img: PropTypes.string,
};
export default withRouter(DropDown);
