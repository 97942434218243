import './style.css';

import React from 'react';
import { useEffect } from 'react';
import { Image, Nav, NavItem, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import back_arrow from '../../assets/images/back_arrow.svg';
import logo from '../../assets/images/logo.svg';
import plus from '../../assets/images/plus.png';
import { useAuthDispatch, useAuthState } from '../../services/authContext';

export function TopNavbar(props) {
  const [currentTitle, setCurrentTitle] = React.useState(
    'Brightline Page Title'
  );
  const [linkTo, setLinkTo] = React.useState('/');
  const [current, setCurrent] = React.useState('/');
  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();
  const { login } = userDetails;
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    setCurrent(currentPath);
    const path = currentPath.split('/');
    headerNameChange(currentPath);
  }, [location]);

  const headerNameChange = (currentPath) => {
    if (currentPath.includes('dashboard')) {
      setCurrentTitle('Dashboard');
      setLinkTo('/dashboard');
    } else if (currentPath.includes('user')) {
      setCurrentTitle('User Management');
      setLinkTo('/users');
    } else if (currentPath.includes('general')) {
      setCurrentTitle('General');
      setLinkTo('/general');
    } else if (currentPath.includes('trainpass')) {
      setCurrentTitle('Train Pass');
      setLinkTo('/trainpass');
    } else if (currentPath.includes('travelpack')) {
      setCurrentTitle('Travel Pack');
      setLinkTo('/travelpack');
    } else if (currentPath.includes('parkingpass')) {
      setCurrentTitle('Parking Pass');
      setLinkTo('/parkingpass');
    } else if (currentPath.includes('extras')) {
      setCurrentTitle('Extras');
      setLinkTo('/extras');
    } else if (currentPath.includes('employeeBenifits')) {
      setCurrentTitle('Employee Benefits');
      setLinkTo('/employeeBenifits');
    } else if (currentPath.includes('corporateDiscount')) {
      setCurrentTitle('Corporate Discount');
      setLinkTo('/corporateDiscount');
    } else if (currentPath.includes('agentPortal')) {
      setCurrentTitle('Agent Portal');
      setLinkTo('/agentPortal');
    } else if (currentPath.includes('credits')) {
      setCurrentTitle('Credits');
      setLinkTo('/credits');
    } else if (currentPath.includes('doortodoor')) {
      setCurrentTitle('Door to Door');
      setLinkTo('/doortodoor');
    } else if (currentPath.includes('settings')) {
      setCurrentTitle('Settings');
      setLinkTo('/settings');
    } else if (currentPath.includes('landing')) {
      setCurrentTitle('Landing');
      setLinkTo('/landing');
    } else {
      setCurrentTitle('Dashboard');
      setLinkTo('/');
    }
  };

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="bg-navbar">
        <Navbar.Brand href="#home" className="navbar-logo">
          <p className="d-inline-block align-top bl-logo-bg" />{' '}
          <img
            alt=""
            src={logo}
            width="110"
            height="30"
            className="d-inline-block align-top bl-logo"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <div>
              <NavItem className="nav-header">
                <div className="route_text">
                  {currentTitle ? currentTitle : ''}
                </div>
              </NavItem>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
