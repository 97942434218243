import actions from './actions';
// Reducer
// the function that accepts our app state, and the action to
// take upon it, which then carries out that action
export default (state, action) => {
  switch (action.type) {
    case actions.FETCH_CONTENT_SUCCESS: {
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload,
          loading: false,
          nextPage: action.payload.nextPage,
          lastPage: action.payload.lastPage,
        },
      };
    }
    case actions.FFETCH_CONTENT_FAILURE:
      return {
        ...state,
        content: {
          ...state.content,
          data: [],
          loading: false,
          nextPage: 0,
          lastPage: 0,
        },
      };

    default:
      return state;
  }
};
