export default {
  userProfile: {
    isLogin: false,
    first_name: '',
    last_name: '',
    name: '',
    emailId: '',
    profile_image_url: '',
  },
  users: {
    data: [],
    loading: false,
    page: 1,
    loadMore: false,
  },
};
