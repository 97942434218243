import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

import webfox from './initialize';

const logError = (error) => {};

const notify = () => {
  toast.dismiss();
  setTimeout(function () {
    toast.error('ACCESS DENIED!!!', {
      position: toast.POSITION.TOP_CENTER,
    });
  }, 1000);
};

const basicToken =
  process.env.REACT_APP_BASIC_AUTH_TOKEN ||
  'RVBHSmtxY1QyM3lmd1FEY2h2Yk46T01LWFdWZmt4V0IzNEsxcWhyS1Q=';

webfox.interceptors.request.use(
  async (config) => {
    const userJson = localStorage.getItem('currentUser');
    const token = userJson !== null ? JSON.parse(userJson).token : '';
    config.headers.Authorization = `Basic ${basicToken}`;
    config.headers['x-access-token'] = token ? token : '';
    return config;
  },
  (error) => Promise.reject(error)
);

webfox.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      notify();
      return Promise.reject(error);
    } else if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const exec = async (fn, params = null, error = null) => {
  const res = {
    data: null,
    error: null,
    syserror: null,
  };

  await fn(params)
    .then((response) => {
      res.data = response;
    })
    .catch((err) => {
      if (err.response) {
        res.error = err.response;
      } else if (err.request) {
        res.error = err.request;
      } else {
        res.error = err;
      }
    });

  return res;
};

const getAllStations = (params) =>
  exec(() => {
    return webfox.get('/garages/stations', { params });
  });
const getAllRoutes = (params) =>
  exec(() => {
    return webfox.get('/train/routes', { params });
  });
const getGarages = (params) =>
  exec(() => {
    return webfox.get('/garages', { params });
  });
const addGarage = (params) =>
  exec(() => {
    return webfox.post('/garages', params);
  });
const updateGarage = (params) =>
  exec(() => {
    return webfox.patch(`/garages/${params.id}`, params.data);
  });
const deleteGarage = (params) =>
  exec(() => {
    return webfox.delete(`/garages/${params.id}`);
  });
const getAllUsers = (params) =>
  exec(() => {
    return webfox.get('/ops/users', { params });
  });
const addUser = (params) =>
  exec(() => {
    return webfox.post('/ops/users', params);
  });
const deleteUser = (params) =>
  exec(() => {
    return webfox.delete(`/ops/users/${params.id}`);
  });
const forgotPassword = (params) =>
  exec(() => {
    return webfox.post('/ops/users/forgot-password', params);
  });
const resetPassword = (params) =>
  exec(() => {
    return webfox.put('/ops/users/change-password', params);
  });
const getTrainPassValue = (params) =>
  exec(() => {
    return webfox.get('products/lp/passes', { params });
  });
const getTrainPassList = (params) =>
  exec(() => {
    return webfox.get('products?p_type=PASSES', { params });
  });

export interface GetPassesListParams {
  from_station: string;
  to_station: string;
  p_type: 'RIDE' | 'PARKING';
}

const getRidePassesListForStation = (params: GetPassesListParams) =>
  exec(() => {
    return webfox.get('products', { params });
  });

const addPass = (params) =>
  exec(() => {
    return webfox.post('products', params);
  });
const addPaymentAllocationItem = (params) =>
  exec(() => {
    return webfox.post('products/payment-allocation', params);
  });
const editPass = (params) =>
  exec(() => {
    return webfox.put(`products/${params.id}`, params.data);
  });
const getSalesTrainPassList = (params) =>
  exec(() => {
    return webfox.get('sales?p-type=passes', { params });
  });
const getRedirectStationList = (params) =>
  exec(() => {
    return webfox.get('redirect-stations', params);
  });
const addPassSale = (params) =>
  exec(() => {
    return webfox.post('sales', params);
  });
const addRedirectStation = (params) =>
  exec(() => {
    return webfox.post('redirect-stations', params);
  });
const updateRedirectStation = (params) =>
  exec(() => {
    return webfox.patch(`redirect-stations/${params.id} `, params.data);
  });
const getInventoryTrainPassList = (params) =>
  exec(() => {
    return webfox.get('products/configs?p_type=PASSES', { params });
  });
const getinventoryMDTAPassList = (params) =>
  exec(() => {
    return webfox.get('products/configs?p_type=MDTAPASSES', { params });
  });
const getDiscountText = (params) =>
  exec(() => {
    return webfox.get(`configs?key-name=${params.id}`, params.data);
  });
const getSubscriptionDiscountData = (params) =>
  exec(() => {
    return webfox.get(`discounts/passes?type=${params.type}`, params.data);
  });
const getSubscription = (params) =>
  exec(() => {
    return webfox.get(`subscriptions?s-type=${params.type}`);
  });
const getRoutes = () =>
  exec(() => {
    return webfox.get(`routes`);
  });
const getPaymentAllocationList = () =>
  exec(() => {
    return webfox.get(`products/payment-allocation`);
  });
const passesSwitch = (params) =>
  exec(() => {
    return webfox.put(`products/configs/${params.id}`, params.data);
  });
const passesMDTASwitch = (params) =>
  exec(() => {
    return webfox.put(`products/configs/${params.id}`, params.data);
  });
const disscountPasses = (params) =>
  exec(() => {
    return webfox.get(
      `discounts/passes/subscriptions/${params.id}`,
      params.data
    );
  });
const updateDiscountPass = (params) =>
  exec(() => {
    return webfox.put(`discounts/passes/${params.id}`, params.data);
  });
const updateDiscountText = (params) =>
  exec(() => {
    return webfox.put(`configs/key-name/${params.id}`, params.data);
  });

const assignEmployeeEmail = (params) =>
  exec(() => {
    return webfox.post('staffs', params);
  });
const searchEmployeeEmail = (params) =>
  exec(() => {
    return webfox.get(`staffs?email=${params.id}`, params.data);
  });
const deleteEmployeeEmail = (params) =>
  exec(() => {
    return webfox.delete(`staffs/${params.id}`, params.data);
  });
const getPacks = (params) =>
  exec(() => {
    return webfox.get('products/lp/packs', params);
  });
const getExistingPacks = (params) =>
  exec(() => {
    return webfox.get(`products?p_type=${params.type}`, params);
  });
const createPack = (params) =>
  exec(() => {
    return webfox.post('/products', params);
  });
const deletePack = (params) =>
  exec(() => {
    return webfox.delete(`products/${params.id}`, params.data);
  });
const addPackSale = (params) =>
  exec(() => {
    return webfox.post('sales', params);
  });
const getPackSale = (params) =>
  exec(() => {
    return webfox.get(`sales?p-type=${params.type}`, params);
  });
const deletePackSales = (params) =>
  exec(() => {
    return webfox.delete(`sales/${params.id}`, params);
  });
const editPackSales = (params) =>
  exec(() => {
    return webfox.put(`sales/${params.id}`, params.data);
  });
const getPacksInventory = (params) =>
  exec(() => {
    return webfox.get('products/configs', params);
  });
const updatePacksInventory = (params) =>
  exec(() => {
    return webfox.put(`products/configs/${params.id}`, params);
  });
const getExtras = (params) =>
  exec(() => {
    return webfox.get('stowage', params);
  });
const getExtraPrivateRidePrice = (params) =>
  exec(() => {
    return webfox.get('extras/4', params);
  });
const getExtraSharedRidePrice = (params) =>
  exec(() => {
    return webfox.get('extras/5', params);
  });
const getExtraParking = (params) =>
  exec(() => {
    return webfox.get('extras/3', params);
  });
const putExtraDisabledStations = (params) =>
  exec(() => {
    return webfox.put(`extras/${params.id}`, params);
  });
const putExtrasPrice = (params) =>
  exec(() => {
    return webfox.put(`extras/extra-types/${params.id}`, params);
  });
const putExtrasInventory = (params) =>
  exec(() => {
    return webfox.put(`stowage/${params.id}`, params);
  });
const putExtraPrivateRidePrice = (params) =>
  exec(() => {
    return webfox.put('/extras/private-car', params);
  });
const putExtraSharedRidePrice = (params) =>
  exec(() => {
    return webfox.patch('/extras/5/prices', params);
  });
const putExtraPrivateRideCount = (params) =>
  exec(() => {
    return webfox.put('garages/stations', params);
  });
const addARDiscount = (params) =>
  exec(() => {
    return webfox.put(`/configs/key-name/${params.id}`, params.data);
  });
const addSales = (params) =>
  exec(() => {
    return webfox.post('/sales', params);
  });
const getSales = (params) =>
  exec(() => {
    return webfox.get(`/sales?p-type=${params.type}`);
  });
const addProduct = (params) =>
  exec(() => {
    return webfox.post('/products', params);
  });
const getProducts = (params) =>
  exec(() => {
    return webfox.get(`/products?p_type=${params.type}`);
  });
const getConfigDetails = (params) =>
  exec(() => {
    return webfox.get(`/configs?key-name=${params.type}`);
  });
const editSales = (params) =>
  exec(() => {
    return webfox.put(`sales/${params.id}`, params.data);
  });
const deleteSales = (params) =>
  exec(() => {
    return webfox.delete(`sales/${params.id}`, params.data);
  });
const deleteRedirectStation = (params) =>
  exec(() => {
    return webfox.delete(`redirect-stations/${params.id}`);
  });
const addAgents = (params) =>
  exec(() => {
    return webfox.post('/agent', params);
  });
const getAgents = (params) =>
  exec(() => {
    return webfox.get(`/agent`);
  });
const deleteAgents = (params) =>
  exec(() => {
    return webfox.put(`agent/${params.id}`, params.data);
  });
const addCredits = (params) =>
  exec(() => {
    return webfox.post('/credits/upload/guest_recovery_file', params);
  });
const getCredits = (params) =>
  exec(() => {
    return webfox.get(`/credits/upload/guest_recovery/history`, { params });
  });
const addDomains = (params) =>
  exec(() => {
    return webfox.post('/corporate/domain', params);
  });
const getDomains = (params) =>
  exec(() => {
    // if (params?.domain_name) {
    //   return webfox.get(
    //     `/corporate/domain?domain_name=${params?.domain_name}`,
    //     { params }
    //   );
    // } else {
    //   return webfox.get(`/corporate/domain`, { params });
    // }
    return webfox.get(`/corporate/domain`, { params });
  });

const getDiscounts = (params) =>
  exec(() => {
    return webfox.get(`/corporate/domain-details/${params.domainId}`);
  });

const updateDiscounts = (params) =>
  exec(() => {
    return webfox.put(`/corporate/domain/corp-domain-service`, params.data);
  });

const deleteDomain = (params) =>
  exec(() => {
    return webfox.delete(`/corporate/domain/${params.id}`);
  });
const updateDomain = (params) =>
  exec(() => {
    return webfox.put(`/corporate/domain/${params.id}`, params.data);
  });

const updateDtoDConfig = (params) =>
  exec(() => {
    return webfox.patch(`/door-to-door/configs/${params.id}`, params);
  });
const getD2dConfig = (params) =>
  exec(() => {
    return webfox.get(`/door-to-door/configs`, params);
  });
const updateServiceWindow = (params) =>
  exec(() => {
    return webfox.patch(
      `/door-to-door/service-window?s_type=${params.s_type}`,
      params
    );
  });

const getDailyParkingPrice = (params) =>
  exec(() => {
    return webfox.get('/garages/stations/price', { params });
  });

const updateParkingPrice = (params) =>
  exec(() => {
    return webfox.patch(`/garages/stations/price`, params);
  });

const getToggleSwitchData = (params) =>
  exec(() => {
    return webfox.get(`/configs?key-name=${params.keyNameId}`);
  });

const updateToggleSwitchData = (params) => {
  return exec(() => {
    return webfox.put(
      `configs/key-name/${params.keyNameId}`,
      params.newToggleData
    );
  });
};
export default {
  logError,
  getAllStations,
  getAllRoutes,
  getGarages,
  addGarage,
  updateGarage,
  deleteGarage,
  getAllUsers,
  addUser,
  deleteUser,
  forgotPassword,
  resetPassword,
  getTrainPassValue,
  getPaymentAllocationList,
  getTrainPassList,
  addPass,
  addPaymentAllocationItem,
  editPass,
  getSalesTrainPassList,
  getRedirectStationList,
  getInventoryTrainPassList,
  getinventoryMDTAPassList,
  addPassSale,
  addRedirectStation,
  updateRedirectStation,
  getDiscountText,
  getSubscriptionDiscountData,
  getSubscription,
  passesSwitch,
  passesMDTASwitch,
  disscountPasses,
  updateDiscountPass,
  updateDiscountText,
  assignEmployeeEmail,
  searchEmployeeEmail,
  deleteEmployeeEmail,
  getPacks,
  getExistingPacks,
  createPack,
  deletePack,
  addPackSale,
  getPackSale,
  deletePackSales,
  editPackSales,
  getPacksInventory,
  updatePacksInventory,
  getExtras,
  getExtraPrivateRidePrice,
  getExtraSharedRidePrice,
  getExtraParking,
  putExtrasInventory,
  putExtrasPrice,
  putExtraDisabledStations,
  putExtraPrivateRidePrice,
  putExtraSharedRidePrice,
  putExtraPrivateRideCount,
  addARDiscount,
  addSales,
  getSales,
  addProduct,
  getProducts,
  getConfigDetails,
  editSales,
  deleteSales,
  deleteRedirectStation,
  addAgents,
  getAgents,
  deleteAgents,
  addCredits,
  getCredits,
  addDomains,
  getDomains,
  getDiscounts,
  updateDiscounts,
  deleteDomain,
  updateDomain,
  updateDtoDConfig,
  getD2dConfig,
  updateServiceWindow,
  getDailyParkingPrice,
  updateParkingPrice,
  getToggleSwitchData,
  updateToggleSwitchData,
  getRoutes,
  getRidePassesListForStation,
};
