export const PrivateRoutingOptions = [
  '/',
  '/dashboard',
  '/user',
  '/user/*',
  '/general',
  '/ridepass',
  '/mdtapass',
  '/trainpass',
  '/travelpack',
  '/parkingpass',
  '/extras',
  '/employeeBenifits',
  '/corporateDiscount',
  '/agentPortal',
  '/credits',
  '/doortodoor',
  '/settings',
];

export const PublicRoutingOptions = ['/login', '/forgot-password', '/landing'];
