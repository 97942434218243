import './leftSideBar.css';

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import dashboard from '../../assets/images/leftSide/dashboard.svg';
import eb from '../../assets/images/leftSide/eb.svg';
import extras from '../../assets/images/leftSide/extras.svg';
import general from '../../assets/images/leftSide/general.svg';
import parking_pass from '../../assets/images/leftSide/parking_pass.svg';
import profile from '../../assets/images/leftSide/profile.png';
import settings from '../../assets/images/leftSide/settings.png';
import train_pass from '../../assets/images/leftSide/train_pass.svg';
import travel_pack from '../../assets/images/leftSide/travel_pack.svg';
import users from '../../assets/images/leftSide/users.png';
import {
  logout,
  useAuthDispatch,
  useAuthState,
} from '../../services/authContext';
import { WebfoxContext } from '../../services/webfox';
import DropDown from '../common/dropdown/Dropdown';

const LeftSideBar = () => {
  const { showAddUser, setShowAddUser } = useContext(WebfoxContext);
  const { showAddRoles, setShowAddRoles } = useContext(WebfoxContext);
  const { showAddWidget, setShowAddWidget } = useContext(WebfoxContext);

  const dispatch = useAuthDispatch();
  const history = useHistory();

  const handleLogout = () => {
    logout(dispatch);
    history.push('/login');
  };

  return (
    <div className="col-md-12 d-none d-md-block flex-column">
      <div className="sidenav" id="sidenav">
        <DropDown
          label="Dashboard"
          img={dashboard}
          route="/dashboard"
        ></DropDown>
        <DropDown label="User Management" img={users} route="/user"></DropDown>
        {/* <DropDown label="General" img={general} route="/general"></DropDown> */}
        <DropDown
          label="Passes 2.0"
          img={train_pass}
          route="/ridepass"
        ></DropDown>
        <DropDown label="MDTA" img={train_pass} route="/mdtapass"></DropDown>
        <DropDown
          label="Train Pass"
          img={train_pass}
          route="/trainpass"
        ></DropDown>
        <DropDown
          label="Travel Pack"
          img={travel_pack}
          route="/travelpack"
        ></DropDown>
        <DropDown
          label="Parking Pass"
          img={parking_pass}
          route="/parkingpass"
        ></DropDown>
        <DropDown label="Extras" img={extras} route="/extras"></DropDown>
        <DropDown
          label="Employee Benefits"
          img={eb}
          route="/employeeBenifits"
        ></DropDown>
        <DropDown
          label="Corporate Discount"
          img={users}
          route="/corporateDiscount"
        ></DropDown>
        <DropDown
          label="Agent Portal"
          img={users}
          route="/agentPortal"
        ></DropDown>
        <DropDown label="Credits" img={users} route="/credits"></DropDown>
        <DropDown
          label="Door to Door"
          img={users}
          route="/doortodoor"
        ></DropDown>

        {/** Sidebar Footer Start */}
        <div className="sidebar-footer">
          <DropDown
            label="Settings"
            img={settings}
            route="/settings"
          ></DropDown>
          <div
            className="dropdown-btn css-flex wrap "
            id="dropdown-btn"
            link-menu="/settings"
          >
            <div
              style={{ display: 'flex', alignItems: 'center', width: '140px' }}
            >
              <img className="v-top" src={profile} />
              <span className="dropdown-title dropdown-title-active">
                <a
                  style={{ textDecoration: 'none', color: '#111' }}
                  href="#"
                  onClick={handleLogout}
                >
                  Logout
                </a>
              </span>
            </div>
            <i className="fa fa-caret-down"></i>
          </div>
        </div>
        {/** Sidebar Footer End */}
      </div>
    </div>
  );
};

export default LeftSideBar;
